<template>
  <td class="info-td cursor-default" @click="openIo">
    <v-row no-gutters wrap>
        <v-col cols="12" class="py-0">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
                <v-icon v-on="on"
                        :class="getClassStratLvl(props.item.strat_lvl)"
                        class="strat-lvl grade"
                        mall>
                  grade
                </v-icon>
            </template>
            <span> Strat Lvl : {{ props.item.strat_lvl == null ? 'standard' : props.item.strat_lvl.toString()}} </span>
          </v-tooltip>
        </v-col>
        <v-col cols="12" class="py-0">
          <v-tooltip bottom>
              <template v-slot:activator="{ on }">
              <v-icon v-on="on" :class="props.item.opti_auto ? 'item-is-on adb' : 'item-is-off'" small>adb</v-icon>
              </template>
              <span> Opti_auto : {{ props.item.opti_auto == null ? 'false' : props.item.opti_auto.toString()}} </span>
          </v-tooltip>
        </v-col>

        <v-col cols="12" class="py-0" v-show="showItemStatus">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon v-on="on" class="item-is-on" :class="statusItem[itemStatus].icon" small>{{statusItem[itemStatus].icon}}</v-icon>
            </template>
            <span> {{statusItem[itemStatus].label}} : true </span>
          </v-tooltip>
        </v-col>

        <v-col cols="12" class="py-0" v-show="activateOttoMinViz">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon v-on="on" :class="props.item.otto_min_viz ? 'item-is-on adb' : 'item-is-off'" small>
                {{iconOttoMinViz}}
              </v-icon>
            </template>
            <span> Otto min viz : {{ props.item.otto_min_viz == null ? 'false' : props.item.otto_min_viz.toString()}} </span>
          </v-tooltip>
        </v-col>
    </v-row>
  </td>
</template>

<script>
import { getClassStratLvl } from '../../../../../utils/stratLvlUtils'
import { activateMixin } from '@/mixins/activateMixin'

export default {
  name: 'InfoIOFragment',
  props: ['props', 'dsp', 'isSurcoucheV2'],
  components: {

  },
  mixins: [
    activateMixin
  ],
  data: function () {
    return {
      statusItem: {
        [this.$DRAFT]: { label: 'Draft', icon: 'opacity' },
        [this.$TO_VALIDATE]: { label: 'To validate', icon: 'hourglass_empty' },
        [this.$VALIDATED]: { label: 'Validated', icon: 'thumb_up' },
        NC: { label: 'Status not defined', icon: 'unknow' }
      }
    }
  },
  created: function () {

  },
  mounted: function () {

  },
  methods: {
    getClassStratLvl (stratLvl) {
      return getClassStratLvl(stratLvl)
    },
    openIo () {
      this.$emit('open-io')
    }
  },
  computed: {
    itemIsDraft () {
      return !this.$isNullOrUndefined(this.props.item.status) && this.props.item.status === this.$DRAFT
    },
    itemIsToValidate () {
      return !this.$isNullOrUndefined(this.props.item.status) && this.props.item.status === this.$TO_VALIDATE
    },
    itemIsValidated () {
      return !this.$isNullOrUndefined(this.props.item.status) && this.props.item.status === this.$VALIDATED
    },
    itemStatus () {
      if (this.itemIsDraft) {
        return this.$DRAFT
      } else if (this.itemIsToValidate) {
        return this.$TO_VALIDATE
      } else if (this.itemIsValidated) {
        return this.$VALIDATED
      } else {
        return 'NC'
      }
    },
    showItemStatus () {
      return this.itemStatus !== 'NC' && this.isSurcoucheV2 === false
    },
    iconOttoMinViz () {
      return this.props.item.otto_min_viz ? 'visibility' : 'visibility_off'
    }
  },
  watch: {
  }
}
</script>

<style scoped>
  .item-is-on.opacity {
    color: darkturquoise !important;
  }

  .item-is-on.hourglass_empty {
    color: #fb8c00 !important;
  }
</style>
