
import { InfoIOFragments } from '@/components/TableComponents/Line/Fragments'
import RetryFastRetryFragment from '@/components/TableComponents/Line/Fragments/RetryFastRetryFragment.vue'
import InstructionGetterSetter from '@/models/Overlay/InstructionGetterSetter'
import RemoveScibidsModel from '@/models/RemoveScibids/RemoveScibidsModel'

import { defineComponent } from 'vue'
import AlertsColumn from '@/components/SurcoucheV2/AlertsColumn.vue'
import HealthColumn from '@/components/SurcoucheV2/HealthColumn.vue'
import LocalizationColumn from '@/components/SurcoucheV2/LocalizationColumn.vue'

export default defineComponent({
  name: 'LineIOV2',
  props: ['selected', 'props', 'dsp', 'dataIsLoading', 'alerts', 'groupKeys'],
  components: {
    LocalizationColumn,
    HealthColumn,
    AlertsColumn,
    RetryFastRetryFragment,
    InfoIOFragments
  },
  data: function () {
    return {
      checkBoxSelected: false,
      isHover: false,
      insGetterSetter: new InstructionGetterSetter(this.props.item, this.dsp)
    }
  },
  created: function () {

  },
  mounted: function () {
  },
  methods: {
    selectAllInstructionsIO () {
      this.$emit('ask-select-item-and-kpi', this.props.item.id, this.props.item, this.checkBoxSelected)
      this.$emit('ask-select-instructions', this.props.item.id_list, this.checkBoxSelected)
    },
    askForEditItem (item: string) {
      this.$emit('edit-item', item)
    },
    askForDuplicateIO (item: string) {
      this.$emit('duplicate-io', item)
    },
    askForOpenStrat (insertionOrderId: string) {
      this.$emit('open-strat', insertionOrderId)
    },
    removeScibids () {
      let removeScibidsData = {
        ioID: this.props.item.io,
        memberExtID: this.insGetterSetter.clientIdPerDsp,
        dsp: this.dsp,
        removeScibidsModel: new RemoveScibidsModel()
      }
      this.$emit('removeScibids', removeScibidsData)
    },
    getGroupKeyPerLineIO (io: string) {
      for (let groupKey in this.groupKeys) {
        let insertionOrderId = this.groupKeys[groupKey].group_key.insertion_order_id
        if (insertionOrderId === io) {
          return this.groupKeys[groupKey]
        }
      }
    },
    openIo () {
      const data = {
        io: this.props.item.io,
        memberId: this.insGetterSetter.clientIdPerDsp,
        advertiserId: this.insGetterSetter.advertiserIdPerDsp,
        dsp: this.dsp
      }
      this.$emit('open-io', data)
    }
  },
  computed: {
  },
  watch: {
  }
})
