
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

@Component({})
export default class RetryFastRetryFragment extends Vue {
  @Prop()
  props!: any
  openIo () {
    this.$emit('open-io')
  }
}
