
import { defineComponent, PropType } from 'vue'
import { Instruction, IoBrief } from '../../../types/instruction_type'
import OverpacedHealth from '@/components/SurcoucheV2/OverpacedHealth.vue'
import KpiLastWeekHealth from '@/components/SurcoucheV2/KpiLastWeekHealth.vue'
import ConstraintHealth from '@/components/SurcoucheV2/ConstraintHealth.vue'

export default defineComponent({
  name: 'HealthColumn',
  components: { ConstraintHealth, KpiLastWeekHealth, OverpacedHealth },
  props: {
    io: {
      type: Object as PropType<IoBrief<Instruction>>
    }
  },
  data: function () {
    return {
    }
  },
  methods: {
    getModelIssues () {
      return null
    },
    openIo () {
      this.$emit('open-io')
    }
  }
})
