
import { defineComponent, PropType } from 'vue'
import { Instruction, IoBrief } from '../../../types/instruction_type'
import { max, min } from 'lodash'
import { maxValue2B } from '@/rules/rules'

export default defineComponent({
  name: 'ConstraintHealth',
  props: {
    io: {
      type: Object as PropType<IoBrief<Instruction>>
    }
  },
  data: function () {
    return {
      htmlConstraintBilling: '',
      htmlViewability: '',
      htmlMargin: '',
      trendConstraintIcon: '',
      trendConstraintPercent: '',
      constraintIconColor: '',
      trendMarginConstraintPercent: '',
      trendMarginConstraintIcon: '',
      marginIconColor: '',
      trendViewabilityConstraintIcon: '',
      trendViewabilityConstrainPercent: '',
      viewabilityIconColor: '',
      constraintIsBold: false,
      marginIsBold: false,
      viewAbilityIsBold: false,
      constraintLast7: null,
      constraintYesterday: null,
      objWatcher: null
    }
  },
  mounted () {
  },
  methods: {
    round (value: number, precision = 4) {
      if (!value) {
        return value
      }
      return this.$commonUtils.roundNum(value, precision)
    },
    formatConstraintData (type: string, last7data: number | null, yesterdayData: number | null) {
      const trend = this.round((yesterdayData - last7data) / last7data * 100, 0)
      if (last7data && yesterdayData) {
        this.setPercentValue(type, trend)
        this.showConstrainticon(yesterdayData, last7data, type)
      }

      let { minValue, maxValue } = this.setMinMaxValue(type)

      if (minValue === null && maxValue === null) {
        console.warn('Error with type value !')
        return
      }

      if (minValue <= last7data && last7data <= maxValue) {
        if (trend > 10) {
          this.setIconColor(type)
        }
        return `${type}: ${minValue} ≤ ${last7data} ≤ ${maxValue}`
      } else if (maxValue && last7data > maxValue) {
        if (trend > 10) {
          this.setIconColor(type)
        }
        if (0.9 * last7data > maxValue) {
          this.setBold(type)
        }
        return `${type}: ${last7data} ${maxValue ? '> ' + maxValue : ''}`
      } else if (minValue && last7data < minValue) {
        if (trend < -10) {
          this.setIconColor(type)
        }
        if (0.9 * last7data < minValue) {
          this.setBold(type)
        }
        return `${type}: ${last7data} ${minValue ? '< ' + minValue : ''}`
      } else {
        if (trend > 10) {
          this.setIconColor(type)
        }
        return `${type}: ${last7data} `
      }
    },
    getCPMConstraintBilling () {
      if (!this.constraintLast7 && !this.constraintYesterday) {
        return ''
      }
      const analyticsCPM: number | null = this.round(this.constraintLast7?.cpm)
      const analyticsCPMYesterday: number | null = this.round(this.constraintYesterday?.cpm)
      this.htmlConstraintBilling = this.formatConstraintData('CPM', analyticsCPM, analyticsCPMYesterday)
    },
    getMarginConstraint () {
      if (!this.constraintLast7?.margin && !this.constraintYesterday?.margin) {
        return ''
      }
      const constraintYesterdayMargin = this.constraintYesterday?.margin
      const constraintLast7Margin = this.constraintLast7?.margin
      this.htmlMargin = this.formatConstraintData('Margin', constraintLast7Margin, constraintYesterdayMargin)
    },
    getViewabilityConstraint () {
      if (!this.constraintLast7 && !this.constraintYesterday) {
        return ''
      }
      const constraintYesterdayViewability = this.constraintYesterday?.viewability
      const constraintLast7Viewability = this.constraintLast7?.viewability
      this.htmlViewability = this.formatConstraintData('Viewability', constraintLast7Viewability, constraintYesterdayViewability)
    },
    showConstrainticon (yesterday: number, last7: number, type?: string) {
      const trendIcon = (yesterday - last7 > 0) ? 'trending-up' : 'trending-down'
      if (type === 'CPM') {
        this.trendConstraintIcon = trendIcon
      } else if (type === 'Margin') {
        this.trendMarginConstraintIcon = trendIcon
      } else if (type === 'Viewability') {
        this.trendViewabilityConstraintIcon = trendIcon
      }
    },
    setIconColor (type: string) {
      if (type === 'Margin') {
        this.marginIconColor = '#880808'
      } else if (type === 'Viewability') {
        this.viewabilityIconColor = '#880808'
      } else if (type === 'CPM') {
        this.constraintIconColor = '#880808'
      }
    },
    setBold (type: string) {
      if (type === 'Margin') {
        this.marginIsBold = true
      } else if (type === 'Viewability') {
        this.viewAbilityIsBold = true
      } else if (type === 'CPM') {
        this.constraintIsBold = true
      }
    },
    setPercentValue (type: string, percent: number) {
      if (type === 'CPM') {
        this.trendConstraintPercent = ` ${percent}% YDA `
      } else if (type === 'Viewability') {
        this.trendViewabilityConstrainPercent = ` ${percent}% YDA `
      } else if (type === 'Margin') {
        this.trendMarginConstraintPercent = ` ${percent}% YDA `
      }
    },
    setMinMaxValue (type: string) {
      let minValue = null
      let maxValue = null
      if (type === 'CPM') {
        minValue = this.round(this.objWatcher?.min_CPM?.value)
        maxValue = this.round(this.objWatcher?.max_CPM?.value)
      } else if (type === 'Viewability') {
        minValue = this.round(this.objWatcher?.min_viz?.value)
        maxValue = null
      } else if (type === 'Margin') {
        minValue = this.round(this.objWatcher?.margin?.value)
        maxValue = null
      }
      return { minValue, maxValue }
    }
  },
  watch: {
    'io': {
      immediate: true,
      deep: true,
      handler: async function () {
        this.objWatcher = this.io.obj_watcher
        this.constraintLast7 = this.io?.analytics_kpi_interfaces?.find((elem) => elem.period === 'last_7_days')
        this.constraintYesterday = this.io?.analytics_kpi_interfaces?.find((elem) => elem.period === 'yesterday')
        this.getCPMConstraintBilling()
        this.getMarginConstraint()
        this.getViewabilityConstraint()
      }
    }
  }
})
